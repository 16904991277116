@import 'normalize.css/normalize.css';
@import './assets/fonts/index.css';
@import 'react-responsive-carousel/lib/styles/carousel.min.css';

:root {
  /* Primary */
  --primary: #3897e8;
  --primary-negative: #ffffff;
  --primary-100: #ebf4fc;
  --primary-100-negative: #3897e8;

  /* Secondary */
  --secondary: #00e5fd;
  --secondary-negative: #ffffff;
  --secondary-100: #e5fcfe;
  --secondary-100-negative: #3897e8;

  /* Tertiary */
  --tertiary: #130f49;
  --tertiary-negative: #ffffff;
  --tertiary-600: #716f91;
  --tertiary-600-negative: #ffffff;
  --tertiary-300: #b8b7c8;
  --tertiary-300-negative: #ffffff;

  /* Quaternary */
  --quaternary: #0f4560;
  --quaternary-negative: #ffffff;
  --quaternary-600: #6f8f9f;
  --quaternary-600-negative: #ffffff;
  --quaternary-400: #9fb4bf;
  --quaternary-400-negative: #ffffff;
  --quaternary-300: #b7c7cf;
  --quaternary-300-negative: #ffffff;

  /* Clear */
  --clear: transparent;
  --clear-negative: #0f4560;

  /* Common colors */
  --green: #12b159;
  --red: #ff445a;
  --orange: #fd9800;
  --white: #ffffff;
  --white-negative: #0f4560;

  /* Status */
  --success: #12b159;
  --danger: #ff445a;
  --warning: #fd9800;

  /* Styles */
  color: var(--clear-negative);
}

* {
  box-sizing: border-box;
  font-family: 'Lexend', Arial, Helvetica, sans-serif;
  box-sizing: border-box;
  line-height: normal;
}

section,
.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: center;
  max-width: 1024px;
  padding: 0 40px;
}

@media only screen and (max-width: 1150px) {
  section,
  .container {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 600px) {
  section,
  .container {
    padding: 0 10px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  margin: 0;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 38px;
}

h3 {
  font-size: 34px;
}

h4 {
  font-size: 28px;
}

h5 {
  font-size: 24px;
}

h6 {
  font-size: 22px;
}

p {
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  margin: 0;
  color: var(--tertiary-600);
}

button {
  font-size: 16px;
  user-select: none;
}

.carousel.carousel-slider {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;
}

.carousel .control-dots {
  bottom: -50px;
}

.carousel .dot {
  box-shadow: none !important;
  background-color: var(--primary) !important;
  opacity: 0.3;
}

.carousel .dot.selected {
  opacity: 1;
}
